import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import { useLocation, Link, useHistory } from 'react-router-dom';
import {
	claimstatus_endpoint
	// notifysender_endpoint
} from 'config/endpoints';
// @import components
import Loader from 'components/loader';
// @import icons
import { ReactComponent as RightArrow } from 'svg/arrowright.svg';
import * as failedAnimation from 'animations/failed.json';
import * as presentAnimation from 'animations/present.json';
// @import utils
import { getToken } from 'utils/temptoken';

const getOptions = (animationName, loop = false) => {
	return {
		loop: loop,
		autoplay: true,
		animationData: animationName.default,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
};

const digitalText = `De identificatie is gelukt! De gever van de geldkaart heeft van ons een melding gekregen en zal het geld daadwerkelijk naar je sturen.\n \nNadat het geld is overgemaakt staat het bedrag de eerstvolgende werkdag op je bankrekening.`;

const physicalText =
	'Je hebt je geldkaart opgehaald. Het bedrag staat uiterlijk  de volgende werkdag op jouw bankrekening. Wil je zelf ook een geldkaart sturen? Download dan nu de app!';

const Claimed = () => {
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const [status, setStatus] = useState(null);
	const [isDigitalOrder, setDigitalOrder] = useState(null);
	const [loading, setLoading] = useState(true);

	const id = searchParams.get('id');

	useEffect(() => {
		setTimeout(() => {
			getToken(id, response => {
				const { token } = response;
				axios
					.get(`${claimstatus_endpoint}${id}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(res => {
						const { claimed, bankaccountVerificationStatus, digitalOrder } =
							res.data;

						const isAccountNotVerified =
							!bankaccountVerificationStatus ||
							bankaccountVerificationStatus === 'new' ||
							bankaccountVerificationStatus === 'disapproved';

						const accountVerified =
							bankaccountVerificationStatus === 'pending' ||
							bankaccountVerificationStatus === 'approved';

						setLoading(false);
						setDigitalOrder(digitalOrder);

						if (claimed || accountVerified) {
							setStatus('success');
							// if (digitalOrder) {
							// 	handleNotification(token);
							// }
						}

						if (
							(!claimed && isAccountNotVerified) ||
							(claimed && isAccountNotVerified)
						) {
							history.push(`/pickup?id=${searchParams.get('id')}`);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			});
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const handleNotification = token => {
	// 	axios
	// 		.post(`${notifysender_endpoint}${id}`, null, {
	// 			headers: {
	// 				Authorization: `Bearer ${token}`
	// 			}
	// 		})
	// 		.then(res => {
	// 			console.log(res.data);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };

	return (
		<div className="contentWrapper claimedScreen">
			{loading && <Loader />}

			{status === 'verification required' && (
				<>
					<div className="wrapper" style={{ textAlign: 'center' }}>
						<Lottie options={getOptions(failedAnimation)} />
						<h1>Oeps!</h1>
						<p>Er is iets mis gegaan</p>
					</div>
					<Link
						to={`pickup?id=${searchParams.get('id')}`}
						className="buttonStyle"
					>
						probeer opnieuw
						<RightArrow />
					</Link>
				</>
			)}
			{status === 'success' && (
				<>
					<div className="wrapper">
						<Lottie options={getOptions(presentAnimation)} />
						<h1>Gelukt!</h1>
						<p>{isDigitalOrder ? digitalText : physicalText}</p>
					</div>
					<a href="https://www.cheqi.nl/app" className="buttonStyle">
						Download de Cheqi app
						<RightArrow />
					</a>
				</>
			)}
		</div>
	);
};

export default Claimed;
