import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// @import components
import Modal from 'components/Modal';
// @import modules
import Loading from 'modules/loading';
import BackButton from 'modules/backIcon';
// import HelpButton from 'modules/helpIcon';
import CheqiOverview from 'modules/cheqiOverview';
// @import constants
// import { mailtoUrl } from 'config/urls';
import {
	product_endpoint,
	orderprices_endpoint,
	checkouturl_endpoint,
	issuerslist_endpoint,
	cancelorder_endpoint,
	specificOrder_endpoint
} from 'config/endpoints';
// import utils
import { getUserToken, getTempToken, handleAlert } from 'utils/functions';
// import images
import CheqiLogo from 'images/cheqi-postnl.png';
import { ReactComponent as DownArrow } from 'svg/down-arrow.svg';
// import styles
import 'css/pages/paycheqi.scss';

const PayCheqi = () => {
	const history = useHistory();
	const location = useLocation();

	const { orderId } = location.state;

	// console.log('orderId:', orderId);

	const [product, setProduct] = useState(null);
	const [couponCode, setCouponCode] = useState();
	const [orderData, setOrderData] = useState(null);
	const [cheqiAmount, setCheqiAmount] = useState();
	// const [discountPrice, setDiscountPrice] = useState();
	const [shippingCost, setShippingCost] = useState(null);

	const [bic, setBic] = useState(null);
	const [errorMsg, setErrorMsg] = useState();
	const [isLoading, setLoading] = useState(true);
	const [issuersList, setIssuersList] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [loaderRequested, setLoaderRequested] = useState(true);

	useEffect(() => {
		getInitialData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getInitialData = () => {
		setLoaderRequested(true);
		getUserToken(userToken => {
			axios
				.get(`${specificOrder_endpoint}${orderId}`, {
					headers: {
						Authorization: `Bearer ${userToken}`
					}
				})
				.then(function (res) {
					// console.log('orderdata:', res.data);
					setOrderData(res.data);
					const cCode = res.data.couponCode;
					if (cCode && !res.data.digitalOrder) {
						setCouponCode(cCode);
					}
					const couponParam =
						!res.data.digitalOrder && (couponCode || cCode)
							? `&couponId=${cCode?.toUpperCase() || couponCode?.toUpperCase()}`
							: '';

					axios
						.get(`${orderprices_endpoint}?orderId=${orderId}${couponParam}`, {
							headers: {
								Authorization: `Bearer ${userToken}`
							}
						})
						.then(function (response) {
							// console.log('prices data:', response.data);
							// setDiscountPrice(
							// 	!res.data.digitalOrder ? response.data.discount : 0
							// );
							setCheqiAmount(response.data.cheqiAmount);
							setShippingCost(response.data.shippingCost);
							if (response.data.product === null) {
								setProduct(null);
							}
							if (
								response.data.product !== null &&
								res.data.productCode !== null &&
								res.data.productCode !== '' &&
								product === null &&
								!res.data.digitalOrder
							) {
								axios
									.get(`${product_endpoint}${res.data.productCode}`, {
										headers: {
											Authorization: `Bearer ${userToken}`
										}
									})
									.then(result => {
										// console.log('products data:', result.data);
										setProduct(result.data);
										if (isLoading) setLoading(false);
										setLoaderRequested(false);
										fetchIssuersList();
									})
									.catch(error => {
										setLoaderRequested(false);
										handleAlert();
										console.log('products error:', error);
									});
							} else {
								if (isLoading) setLoading(false);
								setLoaderRequested(false);
								fetchIssuersList();
							}
						})
						.catch(err => {
							console.log('prices error', err.response.data);
							if (err.response && err.response.data) {
								if (err.response.data.description === 'Coupon code not found') {
									setErrorMsg('Kortingscode niet gevonden');
								}
								// else if (err.response.data.description === '') {
								//   setErrorMsg('Je hebt de kortingscode al gebruikt');
								// }
							}
							setLoaderRequested(false);
						});
				})
				.catch(err => {
					console.log('orderdata error', err.response);
				});
		});
	};

	const fetchIssuersList = () => {
		if (issuersList === null) {
			getTempToken(token => {
				axios
					.get(issuerslist_endpoint, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					})
					.then(function (response) {
						setLoading(false);
						setIssuersList(response.data.data.issuersLists);
					})
					.catch(function (error) {
						handleAlert();
						console.log('issuers list error:', error);
					});
			});
		}
	};

	useEffect(() => {
		if (bic) {
			setLoaderRequested(true);
			getUserToken(fetchCheckoutUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bic]);

	const fetchCheckoutUrl = token => {
		axios
			.get(`${checkouturl_endpoint}${orderData.orderId}/${bic}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(function (response) {
				setLoaderRequested(false);
				if (response.data.url) {
					handleUrl(response.data.url);
				} else {
					handleAlert(
						'Oops...',
						'Het is op dit moment niet mogelijk om je bestelling te betalen. Neem contact met ons op via onderstaande link.'
					);
				}
			})
			.catch(function (error) {
				setLoaderRequested(false);
				handleAlert(
					'Oops...',
					'Het is op dit moment niet mogelijk om je bestelling te betalen. Neem contact met ons op via onderstaande link.'
				);
				console.log(error);
			});
	};

	const fetchPartnerCheckouturl = partnerbic => {
		setLoaderRequested(true);
		getUserToken(token => {
			axios
				.get(
					`${checkouturl_endpoint}${
						orderData.orderId
					}/${partnerbic.toUpperCase()}`,
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				)
				.then(function (response) {
					setLoaderRequested(false);
					// console.log('partnerurl:', response.data);
					if (response.data.succes) {
						history.push({
							pathname: '/pay-result',
							search: `?id=${orderData.orderId}`,
							state: { status: 'completed', orderId: orderData.orderId }
						});
					}
				})
				.catch(function (error) {
					setLoaderRequested(false);
					console.log(error);
				});
		});
	};

	const handleUrl = async url => {
		// todo open url in same route
		window.open(url, '_blank');
		history.push(`/`);
	};

	const handleCancel = () => {
		setLoaderRequested(true);
		getUserToken(cancelCheqi);
	};

	const cancelCheqi = token => {
		axios
			.post(`${cancelorder_endpoint}${orderData.orderId}`, null, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(function (response) {
				setLoaderRequested(true);
				history.push(`/`);
			})
			.catch(function (err) {
				setLoaderRequested(true);
				handleAlert();
				console.log('cancel cheqi error', err.response.data.errors);
			});
	};

	return (
		<>
			<div className={'headerWrapper'}>
				<div className={'headerRow'}>
					<BackButton
						onPress={() => {
							history.push(`/`);
						}}
					/>
					<img src={CheqiLogo} className="cheqiLogo" alt="" />
					{/* <HelpButton
						onClick={() => {
							const subject = 'Hulp met Cheqi';
							const body = `Beste Cheqi,
							Ik heb een vraag over Cheqi "${orderData.orderId}"
							Ik vraag mij af of`;
							var mail = document.createElement('a');
							mail.href = `mailto:${mailtoUrl}?subject=${subject}&body=${body}`;
							mail.click();
						}}
					/> */}
				</div>
			</div>

			{loaderRequested && <Loading />}

			<div className={'viewPagerWrapper payCheqiWrapper maxFrameSize'}>
				{!isLoading && (
					<>
						<strong className={'pagerTitle'}>
							Je gaat deze{' '}
							<span className={'colorSecondary'}>geldkaart versturen</span>
						</strong>

						{orderData.digitalOrder ? (
							<span>
								{`Betaal nu via iDeal om de geldkaart definitief te versturen naar ${orderData.receiverName}`}
							</span>
						) : null}

						<div className={'boxContainer'}>
							<CheqiOverview
								product={product}
								amount={cheqiAmount}
								orderData={orderData}
								couponCode={couponCode}
								// discountPrice={discountPrice}
								message={orderData.senderMessage}
								recieverName={orderData.receiverName}
								digitalOrder={orderData.digitalOrder}
								shippingPrice={orderData.digitalOrder ? 0 : shippingCost}
							/>
						</div>
						{/* {!discountPrice && !orderData.digitalOrder && (
							<div className={'row couponWrapper'}>
								<input
									type="text"
									value={couponCode}
									autoCorrect="false"
									placeholder="kortingscode"
									className={'inputBox'}
									onChange={event => {
										setCouponCode(event.target.value);
									}}
								/>
								<button
									type="button"
									onClick={() => {
										if (couponCode) {
											setErrorMsg();
											getInitialData();
										} else {
											setErrorMsg('Vul eerst een kortingscode in');
										}
									}}
									className={'addCoupon'}
								>
									<span className={'buttonTitle'}>Toepassen</span>
								</button>
							</div>
						)} */}
						{errorMsg && (
							<div>
								<span className={errorMsg}>{errorMsg}</span>
							</div>
						)}

						<div className={'buttonWrapper'}>
							{couponCode && couponCode.toUpperCase() === 'PANEL21' ? (
								<button
									type="button"
									onClick={() => {
										fetchPartnerCheckouturl(couponCode);
									}}
									className={'chooseBank'}
								>
									<span className={'buttonTitle'}>Bevestigen</span>
								</button>
							) : (
								<button
									type="button"
									onClick={() => {
										setModalVisible(true);
									}}
									className={'chooseBank'}
								>
									<img
										alt=""
										className={'idealIcon'}
										src={require('images/ideal-logo.png')}
									/>
									<span className={'buttonTitle'}>Kies je bank</span>
									<DownArrow className={'arrowIcon'} />
								</button>
							)}

							<button
								type="button"
								className={'cancelButton'}
								onClick={handleCancel}
							>
								<span className={'cancelText underlineText colorSecondary'}>
									Annuleer geldkaart
								</span>
							</button>
						</div>

						<Modal
							handleClose={() => setModalVisible(false)}
							isOpen={modalVisible}
						>
							<div className={'bankModalWrapper'}>
								<div className={'listWrapper'}>
									<div className={'modalTitle'}>
										<span className={'pagerTitle'}>Kies je bank</span>
									</div>

									<div className={'issuerWrapper'}>
										{issuersList &&
											issuersList.map((item, i) => {
												return (
													<button
														type="button"
														key={`item_${i}`}
														onClick={() => {
															setBic(item.bic);
															setModalVisible(false);
														}}
														className={'issuer'}
													>
														<img
															alt={item.bic}
															src={item.thumbnail?.url}
															className={'issuerThumbnail'}
														/>
													</button>
												);
											})}
									</div>

									<button
										type="button"
										className={'closeButton'}
										onClick={() => {
											setModalVisible(!modalVisible);
										}}
									>
										<img
											alt=""
											className={'closeIcon'}
											src={require('svg/cancel.svg')}
										/>
									</button>
								</div>
							</div>
						</Modal>
					</>
				)}
			</div>
		</>
	);
};

export default PayCheqi;
